import { render, staticRenderFns } from "./ClaimHistoryPage.vue?vue&type=template&id=dcbcbb6a&scoped=true&"
import script from "./ClaimHistoryPage.vue?vue&type=script&lang=js&"
export * from "./ClaimHistoryPage.vue?vue&type=script&lang=js&"
import style0 from "./ClaimHistoryPage.vue?vue&type=style&index=0&id=dcbcbb6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcbcbb6a",
  null
  
)

/* custom blocks */
import block0 from "./ClaimHistoryPage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports