<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue, { h } from 'vue';
import Component from 'vue-class-component';
import { BTable, BTableSimple, BCol, BRow } from 'bootstrap-vue';

/**
 * Component renders table.
 */
// @vue/component
@Component({
	name: 'BaseTable',
	components: {
		BTable,
		BTableSimple,
		BCol,
		BRow
	},
	inheritAttrs: false,
	props: {
		simple: {
			type: Boolean,
			default: false
		},
		hasTopBar: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			default: 'base-table'
		}
	}
})
export default class BaseTable extends Vue {
	breakpoints = {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200
	};
	stackedSize = null;
	stacked = false;

	created() {
		window.addEventListener('resize', this.handleResize);
	}

	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		this.checkStacked();
		this.$forceUpdate();
	}

	checkStacked() {
		if (this.stackedSize) {
			if (this.stackedSize === true) {
				this.stacked = true;
			} else {
				if (window.innerWidth <= this.breakpoints.sm && this.stackedSize === 'sm') {
					this.stacked = true;
				} else if (window.innerWidth <= this.breakpoints.md && this.stackedSize === 'md') {
					this.stacked = true;
				} else if (window.innerWidth <= this.breakpoints.lg && this.stackedSize === 'lg') {
					this.stacked = true;
				} else {
					this.stacked = false;
				}
			}
		}
	}

	/**
	 * Render the table
	 * @param {function} createElement
	 */
	render() {
		const props = {
			...this.$attrs,
			...this.$props,
			...{ borderless: true, cellspacing: '0' }
		};
		this.stackedSize = props?.stacked;
		const hasFoot =
			Object.keys(this.$scopedSlots).includes('custom-foot') ||
			Object.keys(props).includes('foot-clone');
		const hasTopBar = 'table-caption' in this.$scopedSlots;
		if (hasTopBar) {
			props.captionTop = true;
		}
		const table = h(props?.simple ? 'b-table-simple' : 'b-table', {
			props,
			class: [
				'w-100',
				hasFoot ? 'no-radius' : '',
				{ stacked: this.stacked, 'no-stack': !this.stacked, 'has-top-bar': hasTopBar }
			],
			attrs: { 'data-test-automation-id': props.automationId },
			slots: this.$slots,
			scopedSlots: this.$scopedSlots
		});
		this.checkStacked();
		return table;
	}
}
</script>

<style lang="scss" scoped>
::v-deep thead th {
	font-family: $josefin-font-family;
	font-size: 16px;
	font-weight: normal;
	white-space: pre-wrap;
}
::v-deep tr:nth-child(odd) {
	background-color: $white;
}
::v-deep tr:nth-child(even) {
	background-color: $very-light-grey;
}
.no-radius {
	& ::v-deep tbody td {
		border-radius: 0 !important;
	}
}
/* stylelint-disable no-descending-specificity */
// disable stylelint here because these two classes will never be applied at the same time
.stacked {
	& ::v-deep tr {
		padding: 15px;
		border: 1px solid $gray-light;
		border-bottom: none;
	}
	& ::v-deep tr:last-child {
		border-bottom: 1px solid $gray-light;
	}
	& ::v-deep tr:nth-child(even) {
		background-color: $gray-very-light;
	}
}
.no-stack {
	border-collapse: separate;
	border-spacing: 0;
	& .btn {
		font-family: $josefin-font-family;
		font-size: 15px;
	}
	& ::v-deep td,
	::v-deep th {
		border: 1px solid $gray-light;
		border-right: none;
		padding: 10px;
	}
	& ::v-deep tfoot th {
		background-color: $gray-light;
		color: $black;
		border-bottom: none;
		font-size: 16px;
		font-weight: 400;
		font-family: $josefin-font-family;
	}
	& ::v-deep thead th {
		background-color: $gray-light;
		color: $black;
		border-bottom: none;
		font-size: 16px;
		font-weight: 400;
		font-family: $josefin-font-family;
		&:first-child {
			border-radius: 5px 0 0 0;
		}
		&:last-child {
			border-radius: 0 5px 0 0;
		}
		&:not(:first-child) {
			border-left: none;
		}
	}
	& ::v-deep tfoot th:last-child {
		border-radius: 0 0 5px 0;
	}
	& ::v-deep tfoot th:first-child {
		border-radius: 0 0 0 5px;
	}
	& ::v-deep td {
		font-size: 15px;
	}
	& ::v-deep tbody td {
		border-bottom: none;
	}
	& ::v-deep td:last-child {
		border-right: 1px solid $gray-light;
	}
	& ::v-deep tbody td:not(:first-child) {
		border-left: none;
	}
	& ::v-deep tfoot :not(:first-child) {
		border-left: none;
	}
	& ::v-deep tbody tr {
		@include media-breakpoint-down(md) {
			& td > div {
				width: 100% !important;
				padding: 0 !important;
			}
			& td::before {
				content: '' !important;
				width: auto !important;
			}
		}
	}
	& ::v-deep tbody tr:last-child {
		& td {
			border-bottom: 1px solid $gray-light;
		}
		& td:first-child {
			border-radius: 0 0 0 5px;
		}
		& td:last-child {
			border-radius: 0 0 5px 0;
		}
	}
}
/* stylelint-enable no-descending-specificity */
</style>
