<template>
	<div>
		<BRow class="name-header pt-2 m-0">
			<BCol sm="12" md="6" class="header">{{ claimSearchResults.participantName }} </BCol>
			<BCol sm="12" md="6" class="dob text-md-right d-flex justify-content-md-end"
				>{{ $t('header.dob') }} {{ formattedDob }}
			</BCol>
		</BRow>
		<BaseTable
			:items="prepareData"
			stacked="md"
			:fields="getColumns()"
			:current-page="currentPage"
			small
			:class="['base-table-styles']"
		>
			<template #head(excludedAmount)="data">
				<span class="text-info">{{ data.label }}</span>
				<InfoModal
					class="excluded-amount-info-button-header"
					automation-id="excluded-amount-popup"
					:modal-title="$t('modal.title')"
				>
					{{ $t('modal.text') }}
				</InfoModal>
			</template>
			<template #cell(excludedAmount)="row">
				<span>{{ row.value }}</span>
				<InfoModal
					class="excluded-amount-info-button-body"
					automation-id="excluded-amount-popup"
					:modal-title="$t('modal.title')"
				>
					{{ $t('modal.text') }}
				</InfoModal>
			</template>
			<template #custom-foot>
				<template v-if="showTotal">
					<BTr aria-hidden="true" class="table-footer">
						<BTh hidden="true" aria-hidden="true" colspan="6" scope="col"> </BTh>
					</BTr>
					<BTr class="table-footer">
						<BTd colspan="2" :scope="undefined" role="cell">
							<span>{{ $t('footer.total') }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ submittedAmountTotal }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ paidAmountTotal }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ deductibleAmountTotal }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ excludedAmountTotal }}</span>
							<InfoModal
								class="excluded-amount-info-button-footer"
								automation-id="excluded-amount-popup"
								:modal-title="$t('modal.title')"
							>
								{{ $t('modal.text') }}
							</InfoModal>
						</BTd>
					</BTr>
				</template>
				<BTr aria-hidden="true" class="table-blank"><BTh colspan="6"></BTh> </BTr>
				<template v-if="showGrandTotal">
					<BTr aria-hidden="true" class="table-footer-grand">
						<BTh hidden="true" aria-hidden="true" colspan="6" scope="col"> </BTh>
					</BTr>
					<BTr class="table-footer-grand">
						<BTd colspan="2" :scope="undefined" role="cell">
							<span class="grand-total">{{ $t('footer.grandTotal') }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ submittedAmountGrandTotalFormatted }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ paidAmountGrandTotalFormatted }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ deductibleAmountGrandTotalFormatted }}</span>
						</BTd>
						<BTd :scope="undefined" role="cell">
							<span class="float-right">{{ excludedAmountGrandTotalFormatted }}</span>
						</BTd>
					</BTr>
				</template>
			</template>
		</BaseTable>
		<ClaimSearchResultsTableFooterStacked
			:submitted-amount-total="submittedAmountTotal"
			:paid-amount-total="paidAmountTotal"
			:deductible-amount-total="deductibleAmountTotal"
			:excluded-amount-total="excludedAmountTotal"
			:submitted-amount-grand-total="submittedAmountGrandTotalFormatted"
			:paid-amount-grand-total="paidAmountGrandTotalFormatted"
			:deductible-amount-grand-total="deductibleAmountGrandTotalFormatted"
			:excluded-amount-grand-total="excludedAmountGrandTotalFormatted"
			:show-grand-total="showGrandTotal"
			:show-total="showTotal"
			@showInfoPopup="showInfoPopup()"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { formatDateForUi } from '@/utils/date';
import { BTr, BTd, BTh, BTable, BRow, BCol } from 'bootstrap-vue';
import BaseTable from '@/components/common/base/BaseTable';
import BaseButton from '@/components/common/base/BaseButton';
import InfoModal from '@/components/common/InfoModal';
import DisplayCurrency from '@/components/common/DisplayCurrency';
import ClaimSearchResultsTableFooterStacked from '@/components/claim/claim-history/ClaimSearchResultsTableFooterStacked';

@Component({
	name: 'ClaimSearchResults',
	components: {
		BaseTable,
		BTr,
		BTd,
		BTh,
		BRow,
		BCol,
		BTable,
		BaseButton,
		InfoModal,
		DisplayCurrency,
		ClaimSearchResultsTableFooterStacked
	},
	props: {
		claimSearchResults: {
			type: Object,
			default: () => {},
			required: true
		},
		perPage: {
			type: Number,
			default: 20
		},
		submittedAmountGrandTotal: {
			type: String,
			required: true
		},
		paidAmountGrandTotal: {
			type: String,
			required: true
		},
		deductibleAmountGrandTotal: {
			type: String,
			required: true
		},
		excludedAmountGrandTotal: {
			type: String,
			required: true
		},
		showGrandTotal: {
			type: Boolean,
			default: false
		},
		showTotal: {
			type: Boolean,
			default: false
		}
	},
	mixins: [IdMixin]
})
export default class ClaimSearchResults extends Vue {
	currentPage = 1;
	formattedDob = null;
	totalRows =
		this.claimSearchResults && this.claimSearchResults.claimLines
			? this.claimSearchResults.claimLines.length
			: 0;
	isModalVisible = false;

	mounted() {
		this.currentPage = 1;
		if (this.claimSearchResults.participantDob) {
			this.formattedDob = formatDateForUi(
				this.claimSearchResults.participantDob,
				this.$store.state.i18n.locale
			);
		}
	}

	getFooterData() {
		let bottomContent = [
			{
				total: this.$t('footer.total'),
				submittedAmountTotal: this.submittedAmountTotal,
				paidAmountTotal: this.paidAmountTotal,
				deductibleAmountTotal: this.deductibleAmountTotal,
				excludedAmountTotal: this.excludedAmountTotal
			}
		];
		return bottomContent;
	}
	getSearchTotalAmounts() {
		let bottomContent = [
			{
				total: this.$t('footer.total'),
				submittedAmountGrandTotal: this.submittedAmountGrandTotalFormatted,
				paidAmountGrandTotal: this.paidAmountGrandTotalFormatted,
				deductibleAmountGrandTotal: this.deductibleAmountGrandTotalFormatted,
				excludedAmountGrandTotal: this.excludedAmountGrandTotalFormatted
			}
		];
		return bottomContent;
	}

	get prepareData() {
		var self = this;
		let index = 0;
		var preparedData = [];
		this.claimSearchResults.claimLines.forEach(function (claimLines) {
			if (self.isEnglish) {
				preparedData.push({
					id: index,
					serviceDate: formatDateForUi(claimLines.serviceDate, 'en'),
					benefitDesc: claimLines.benefitDesc,
					submittedAmount: Number(claimLines.submittedAmount).toLocaleString('en-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					paidAmount: Number(claimLines.paidAmount).toLocaleString('en-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					deductibleAmount: Number(claimLines.deductibleAmount).toLocaleString('en-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					excludedAmount: Number(claimLines.excludedAmount).toLocaleString('en-CA', {
						style: 'currency',
						currency: 'CAD'
					})
				});
			} else {
				preparedData.push({
					id: index,
					serviceDate: formatDateForUi(claimLines.serviceDate, 'fr'),
					benefitDesc: claimLines.benefitDesc,
					submittedAmount: Number(claimLines.submittedAmount).toLocaleString('fr-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					paidAmount: Number(claimLines.paidAmount).toLocaleString('fr-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					deductibleAmount: Number(claimLines.deductibleAmount).toLocaleString('fr-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					excludedAmount: Number(claimLines.excludedAmount).toLocaleString('fr-CA', {
						style: 'currency',
						currency: 'CAD'
					})
				});
			}
			index++;
		});
		return preparedData;
	}

	getDetails(item) {
		this.$emit('details', item);
	}

	pageChange(page) {
		this.currentPage = page;
	}

	/**
	 * Returns the list of columns
	 * @returns {array}
	 */
	getColumns() {
		return [
			{
				key: 'serviceDate',
				label: this.$t('column.serviceDate'),
				thClass: 'th-date-class',
				tdClass: 'td-date-class'
			},
			{
				key: 'benefitDesc',
				label: this.$t('column.benefitDesc'),
				thClass: 'th-benefit-desc-class',
				tdClass: 'th-benefit-desc-class'
			},
			{
				key: 'submittedAmount',
				label: this.$t('column.submittedAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'paidAmount',
				label: this.$t('column.paidAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'deductibleAmount',
				label: this.$t('column.deductibleAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'excludedAmount',
				label: this.$t('column.excludedAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			}
		];
	}

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get submittedAmountTotal() {
		return this.totalAmountFormatted(this.claimSearchResults.totalSubmittedAmount);
	}

	get paidAmountTotal() {
		return this.totalAmountFormatted(this.claimSearchResults.totalPaidAmount);
	}

	get deductibleAmountTotal() {
		return this.totalAmountFormatted(this.claimSearchResults.totalDeductibleAmount);
	}

	get excludedAmountTotal() {
		return this.totalAmountFormatted(this.claimSearchResults.totalExcludedAmount);
	}

	get submittedAmountGrandTotalFormatted() {
		return this.totalAmountFormatted(this.submittedAmountGrandTotal);
	}

	get paidAmountGrandTotalFormatted() {
		return this.totalAmountFormatted(this.paidAmountGrandTotal);
	}

	get deductibleAmountGrandTotalFormatted() {
		return this.totalAmountFormatted(this.deductibleAmountGrandTotal);
	}

	get excludedAmountGrandTotalFormatted() {
		return this.totalAmountFormatted(this.excludedAmountGrandTotal);
	}

	totalAmountFormatted(amount) {
		if (this.isEnglish) {
			return Number(amount).toLocaleString('en-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		} else {
			return Number(amount).toLocaleString('fr-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		}
	}

	showInfoPopup() {
		this.isModalVisible = true;
	}

	changeModalVisibility(visibility) {
		this.isModalVisible = visibility;
	}
}
</script>

<style lang="scss" scoped>
.table-header {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	height: 50px;
	color: $white;
}
.header-name {
	font-size: 21px;
	font-family: $font-family-headline;
}
.header-dob {
	font-size: 17px;
	font-family: $font-family-regular;
	font-weight: 200;
	float: right;
}
.table-blank th {
	height: 20px;
	background-color: $gray-very-light !important;
	border: none;
}
.table-footer ::v-deep tr {
	background-color: $gray-very-light;
}
.base-table-styles ::v-deep tfoot tr {
	padding-top: 13px;
	padding-right: 13px;
	padding-left: 13px;
	padding-bottom: 11px;
}
.base-table-styles ::v-deep tr:nth-child(even) {
	background-color: $gray-very-light;
}

.base-table-styles {
	border-collapse: collapse;
	background-color: #ffffff;
}

.base-table-styles ::v-deep th {
	font-size: 15px;
	font-family: $font-family-headline;
	font-weight: 300;
}
.base-table-styles ::v-deep td {
	font-size: 15px;
	font-family: $font-family-regular;
	font-weight: 300;
}
.base-table-styles ::v-deep .table-footer td {
	font-size: 15px;
	font-family: $font-family-regular;
	height: 40px;
	padding-top: 13px;
	padding-right: 13px;
	padding-left: 13px;
	padding-bottom: 11px;
	font-weight: bold;
}
.base-table-styles ::v-deep .table-footer-grand td {
	font-size: 15px;
	font-family: $font-family-regular;
	height: 40px;
	padding-top: 13px;
	padding-right: 13px;
	padding-left: 13px;
	padding-bottom: 11px;
	background-color: $blue-dark;
	color: white;
	margin-top: 20px;
	font-weight: bold;
	border: none;
}
.base-table-styles ::v-deep tbody tr [data-label]::before {
	font-size: 15px;
	font-family: $font-family-headline;
	font-weight: 300;
	position: relative;
	top: 3px;
}

// Amount column
.base-table-styles ::v-deep .th-amount-class {
	text-align: right;
}
.base-table-styles ::v-deep .td-amount-class {
	text-align: right;
	@media (max-width: 767px) {
		text-align: left;
	}
}
// Details column
.base-table-styles ::v-deep .td-details-class {
	text-align: left;
}
.top-paragraph {
	font-family: $font-family-headline;
	font-size: 21px;
	margin-bottom: 10px;
}
.bottom-paragraph {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
}
.date-range-and-total-amount {
	margin-bottom: 20px;
}
.results-for-text {
	margin: 0px;
	font-family: $font-family-headline;
	font-size: 21px;
}
.statement-search-date-range {
	float: left;
	@media (max-width: 550px) {
		float: none;
		display: block;
	}
}
.statement-search-paid-amount {
	font-weight: 500;
	float: right;
	@media (max-width: 550px) {
		float: none;
		display: block;
	}
}
.table-footer ::v-deep td:first-child {
	border-radius: 0 0 0 5px;
}
.table-footer ::v-deep td:last-child {
	border-radius: 0 0 5px 0;
}
.table-footer-grand ::v-deep td:first-child {
	border-radius: 5px 0 0 5px !important;
}
.table-footer-grand ::v-deep td:last-child {
	border-radius: 0 5px 5px 0 !important;
}
.name-header {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	height: 100%;
	color: $white;
}
.header {
	font-size: 21px;
	font-family: $josefin-font-family;
	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 10px;
	@media (max-width: 767px) {
		padding-bottom: 0px;
	}
}
.dob {
	font-size: 17px;
	font-family: $lato-font-family;
	padding-top: 5px;
	padding-left: 10px;
	@media (max-width: 767px) {
		padding-top: 0px;
		padding-bottom: 10px;
	}
}
.excluded-amount-info-button {
	padding: 0px;
}
.excluded-amount-info-button-header {
	padding: 0px;
	display: inline;
	@media (max-width: 768px) {
		display: none;
	}
}
.excluded-amount-info-button-footer {
	padding: 0px;
	display: inline;
	@media (min-width: 768px) {
		display: none;
	}
}
.excluded-amount-info-button-body {
	padding: 0px;
	display: inline;
	@media (min-width: 768px) {
		display: none;
	}
}
.base-table-styles ::v-deep .th-benefit-desc-class {
	@media (min-width: 768px) {
		width: 200px;
	}
}
.base-table-styles ::v-deep .tr-benefit-desc-class {
	@media (min-width: 768px) {
		width: 200px;
	}
}
</style>

<i18n>
{
  "en": {
	"column": {
		"serviceDate": "Service Date",
		"benefitDesc": "Benefit description",
		"submittedAmount": "Submitted amount",
		"paidAmount": "Blue Cross paid",
        "deductibleAmount": "Deductible",
        "excludedAmount": "Excluded amount"
	},
	"modal": {
		"title": "Not sure why some of your payment was excluded?",
		"text": "On your Payment Details page, under the Explanation of Benefits, you'll find clarification of why some of your claim was not covered or eligible for payment."
	},
	"header": {
		"dob": "Date of birth:"
	},
	"footer": {
		"total": "Total",
		"grandTotal": "Grand total"
	}
  },
  "fr": {
	"column": {
		"serviceDate": "Date de service",
		"benefitDesc": "Description de la garantie",
		"submittedAmount": "Montant soumis",
		"paidAmount": "Croix Bleue a payé",
        "deductibleAmount": "Franchise",
        "excludedAmount": "Montant exclu"
	},
	"modal": {
		"title": "Pourquoi une partie de votre paiement est-elle exclue?",
		"text": "À la page Détails sur le paiement, sous la section Relevé des prestations, vous trouverez les raisons pour lesquelles une partie de votre demande de règlement n’était pas admissible au remboursement."
	},
	"header": {
		"dob": "Date de naissance:"
	},
	"footer": {
		"total": "Total",
		"grandTotal": "Grand Total"
	}
  }
}
</i18n>
