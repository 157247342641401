<template>
	<ClaimHistoryTemplate :page-title="$t('title')">
		<p class="payment-history-page-description">
			{{ $t('subtitle') }}
		</p>
		<BaseAlert class="no-pad" variant="warning" alternate>
			{{ $t('alert.header') }}

			<template #description><br />{{ $t('alert.text') }}</template>
		</BaseAlert>
		<BaseCollapse
			:button-label="$t('button.search')"
			button-variant="primary"
			:icon-two="['fal', 'search']"
			:visible="isVisible"
			class="claim-history-collapse"
			automation-id="claimHistoryPage"
			@input="changeIsVisible"
		>
			<ClaimSearchFields
				automation-id="claimHistoryPageFields"
				:initial-search-criteria="searchCriteria"
				:participants="availableParticipants"
				@click="updateSearchCriteriaAndGetData"
			/>
		</BaseCollapse>
		<div v-for="(item, index) in claims" :key="item.participantId">
			<template v-if="!isEmptyResults">
				<ClaimSearchResults
					:claim-search-results="item"
					:submitted-amount-grand-total="grandTotalSubmittedAmount"
					:paid-amount-grand-total="grandTotalTotalPaidAmount"
					:deductible-amount-grand-total="grandTotalDeductibleAmount"
					:excluded-amount-grand-total="grandTotalExcludedAmount"
					:show-grand-total="index == claims.length - 1"
					:show-total="claims.length > 1"
				/>
			</template>
		</div>
		<BaseButton
			v-if="!isEmptyResults"
			class="download-pdf-button"
			variant="primary"
			:label="$t('button.download')"
			pill
			is-right
			:icon="['fal', 'print']"
			icon-position="left"
			:automation-id="getAutomationId('downloadClaimHistoryPDFButton')"
			:data-tracking-id="getAutomationId('downloadClaimHistoryPDFButton')"
			@click="downloadClaimHistoryPDF()"
		></BaseButton>
		<ErrorModal
			:error-title="$t('modal.title')"
			:error-message="$t('modal.message')"
			:has-close-button="false"
			:modal-show="showErrorModal"
			@change="closeErrorModal"
		>
			<BaseButton
				class="mt-2"
				:label="$t('button.ok')"
				:aria-label="$t('button.ok')"
				variant="primary"
				automation-id="noClaimHistoryButton"
				@click="closeErrorModal"
			/>
		</ErrorModal>
	</ClaimHistoryTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseAlert from '@/components/common/alert/BaseAlert';
import ClaimSearchFields from '@/components/claim/claim-history/ClaimSearchFields';
import ClaimSearchResults from '@/components/claim/claim-history/ClaimSearchResults';
import BaseCollapse from '@/components/common/base/BaseCollapse';
import Dependent from '@/models/Dependent.js';
import ClaimHistory from '@/models/ClaimHistory.js';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import ClaimHistoryTemplate from '@/pages/claim-history/ClaimHistoryTemplate.vue';
import { format } from 'date-fns';

// @vue/component
@Component({
	name: 'ClaimHistoryPage',
	components: {
		BaseAlert,
		ClaimSearchFields,
		ClaimSearchResults,
		BaseCollapse,
		ErrorModal,
		BaseButton,
		ClaimHistoryTemplate
	},
	mixins: [IdMixin],
	watch: {
		locale() {
			if (!this.isEmptyResults) {
				this.updateSearchCriteriaAndGetDataLanChange(this.searchCriteria);
			}
			this.updateParticipants();
		}
	}
})
export default class ClaimHistoryPage extends Vue {
	isVisible = true;
	claims = []; // Reactive search results
	isEmptyResults = true;
	grandTotalSubmittedAmount = '0.00';
	grandTotalTotalPaidAmount = '0.00';
	grandTotalDeductibleAmount = '0.00';
	grandTotalExcludedAmount = '0.00';
	allClaimTypes = [];
	allParticipants = [];
	participants = [{ value: 'all', text: this.$t('baseSelectValue.all'), active: true }];
	showErrorModal = false;
	searchCriteria = {
		fromDate: null,
		toDate: null,
		claimTypes: null,
		participant: null
	};

	get locale() {
		return this.$root.$i18n.locale;
	}

	async beforeMount() {
		this.$store.dispatch('updateLoading', true);
		await this.getMembers();
		this.getBenefits();
		this.claims = (await ClaimHistory.getSavedSearchResults(sessionStorage.getItem('email'))) || [];

		this.isEmptyResults = !this.claims?.length || false;

		if (this.isEmptyResults) {
			this.searchCriteria = {
				fromDate: format(
					new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
					'yyyy-MM-dd'
				),
				toDate: this.getMaxDate(),
				claimTypes: 'all',
				participant: 'all'
			};
			await this.claimHistoryCall();
			this.isEmptyResults = !this.claims?.length || false;

			if (!this.isEmptyResults) {
				this.generateGrandTotals();
			}
		}
		if (!this.isEmptyResults) {
			this.generateGrandTotals();
		}
		this.$store.dispatch('updateLoading', false);
	}

	changeIsVisible() {
		this.isVisible = !this.isVisible;
	}

	closeErrorModal() {
		this.showErrorModal = false;
	}

	async updateSearchCriteriaAndGetData(searchCriteria) {
		this.$store.dispatch('updateLoading', true);
		if (searchCriteria) {
			this.searchCriteria = searchCriteria;
			await this.claimHistoryCall();
		}
		// Check if any claims found
		this.isEmptyResults = !this.claims?.length || false;

		if (this.isEmptyResults) {
			this.showErrorModal = true;
		} else {
			this.generateGrandTotals();
		}
		this.$store.dispatch('updateLoading', false);
	}

	async updateSearchCriteriaAndGetDataLanChange(searchCriteria) {
		this.$store.dispatch('updateLoading', true);
		if (searchCriteria?.fromDate) {
			this.searchCriteria = searchCriteria;
			await this.claimHistoryCall();
		} else {
			var tempSearchCriteria =
				(await ClaimHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};

			if (tempSearchCriteria?.fromDate) {
				this.searchCriteria = tempSearchCriteria;

				await this.claimHistoryCall();
			}
			this.$store.dispatch('updateLoading', false);
		}
		// Check if any claims found
		this.isEmptyResults = !this.claims?.length || false;

		if (this.isEmptyResults) {
			this.showErrorModal = true;
		} else {
			this.generateGrandTotals();
		}

		this.$store.dispatch('updateLoading', false);
	}

	async updateParticipants() {
		//get participant all field to be translated on language toggle.
		let objIndex = this.participants.findIndex((obj) => obj.value === 'all');

		//Update object's text property.
		this.participants[objIndex].text = this.$t('baseSelectValue.all');
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	async getMembers() {
		this.$store.dispatch('updateLoading', true);
		await Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			if (response?.data && response?.status === 200) {
				for (const index in response.data) {
					this.participants.push({
						value: response.data[index].participantId,
						text: `${response.data[index].firstName} ${response.data[index].lastName}`,
						active: response.data[index].active
					});
					this.allParticipants.push(response.data[index].participantId);
				}
			} else {
				this.$store.dispatch('error');
			}
		});
		this.$store.dispatch('updateLoading', false);
	}

	getBenefits() {
		this.benefits = [
			{ value: 'all', text: this.$t('benefit.all') },
			{ value: '05', text: this.$t('benefit.dental') },
			{ value: '04', text: this.$t('benefit.drug') },
			{ value: '03', text: this.$t('benefit.eob') },
			{ value: '02', text: this.$t('benefit.travel') },
			{ value: '01', text: this.$t('benefit.hospital') }
		];
		for (const index in this.benefits) {
			if (this.benefits[index].value !== 'all') {
				this.allClaimTypes.push(this.benefits[index].value);
			}
		}
	}

	generateGrandTotals() {
		var tempGrandTotalSubmittedAmount = 0;
		var tempGrandTotalTotalPaidAmount = 0;
		var tempGrandTotalDeductibleAmount = 0;
		var tempGrandTotalExcludedAmount = 0;
		for (var i = 0; i < this.claims.length; i++) {
			tempGrandTotalSubmittedAmount += +this.claims[i].totalSubmittedAmount;
			tempGrandTotalTotalPaidAmount += +this.claims[i].totalPaidAmount;
			tempGrandTotalDeductibleAmount += +this.claims[i].totalDeductibleAmount;
			tempGrandTotalExcludedAmount += +this.claims[i].totalExcludedAmount;
		}
		this.grandTotalSubmittedAmount = tempGrandTotalSubmittedAmount.toString();
		this.grandTotalTotalPaidAmount = tempGrandTotalTotalPaidAmount.toString();
		this.grandTotalDeductibleAmount = tempGrandTotalDeductibleAmount.toString();
		this.grandTotalExcludedAmount = tempGrandTotalExcludedAmount.toString();
	}

	/**
	 * Handle on click event.
	 */
	async downloadClaimHistoryPDF() {
		this.$store.dispatch('updateLoading', true);
		let pdfByte = await ClaimHistory.getClaimHistoryPDF(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			this.searchCriteria,
			this.allClaimTypes,
			this.allParticipants
		);
		if (pdfByte) {
			var titleString = this.$t('title');
			var filename = titleString.replaceAll(' ', '-');
			this.downloadClaimHistoryPdf(pdfByte, filename);
		} else {
			this.$store.dispatch('error');
		}
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	downloadClaimHistoryPdf(data, filename) {
		if (navigator.appVersion.toString().indexOf('.NET') > 0)
			window.navigator.msSaveBlob(new Blob([data]), `Identification.pdf`);
		else {
			const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			const fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);

			fileLink.click();
		}
	}

	async claimHistoryCall() {
		this.claims = await ClaimHistory.getClaimHistory(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			this.searchCriteria,
			this.allClaimTypes,
			this.allParticipants
		);
	}

	/**
	 * Return the list of active participant for the search field
	 */
	get availableParticipants() {
		return this.participants.filter((participant) => {
			return participant.active;
		});
	}

	/**
	 * Used to determine if the user is terminated or not.
	 */
	isUserActive(participantId) {
		return this.participants.find((participant) => {
			return participantId === participant.value;
		}).active;
	}
}
</script>
<style lang="scss" scoped>
.claim-history-collapse {
	margin-bottom: 30px;
}
.download-pdf-button {
	margin-top: 40px;
	margin-bottom: 40px;
}
.claim-history-page-description {
	font-size: 17px;
	margin-top: 0px;
	margin-bottom: 20px;
	margin-left: 0px;
	margin-right: 0px;
}
</style>
<i18n>
{
    "en": {
        "title": "Claims History",
        "subtitle": "Review or search for claims that you or anyone on your plan submitted.",
        "alert": {
            "header": "Don’t see a claim you’ve recently submitted?",
            "text": "If you’ve submitted a claim to us and it’s not showing up here, our claims analysts may not have had a chance to review it yet. If a claim did not result in any payment, or it’s from a wellness or health spending account, it will only appear on your Payment History screen."
        },
        "button": {
			"search": "Search your claims",
			"download": "Download",
			"ok": "OK"
        },
		"baseSelectValue" : {
			"all" : "All"
		},
        "benefit": {
            "hospital": "Hospital",
            "travel": "Travel",
            "eob": "Extended Health Benefits",
            "drug": "Drug",
            "dental": "Dental",
            "all": "All"
        },
		"modal": {
			"title": "No results found.",
			"message": "We couldn't find anything matching your request. Please try a new search."
		}
    },
    "fr": {
        "title": "Historique des demandes de règlement",
        "subtitle": "Examinez ou recherchez les demandes de règlement que vous ou un(e) participant(e) de votre régime avez soumises.",
         "alert": {
            "header": "Vous ne voyez pas une demande de règlement que vous avez récemment soumise?",
            "text": "Si vous nous avez soumis une demande de règlement en ligne et que vous ne la voyez pas ici, il se peut que nos analystes des demandes de règlement n’aient pas encore eu l’occasion de la traiter. Si une demande de règlement n’a pas été remboursée ou l’a été par un compte Gestion-santé ou Gestion mieux-être, elle s’affichera uniquement à l’écran Historique de paiement."
        },
         "button": {
			"search": "Rechercher une demande de règlement",
			"download": "Télécharger",
			"ok": "OK"
		},
		"baseSelectValue" : {
			"all" : "Tout"
		},
        "benefit": {
            "hospital": "Hôpital",
            "travel": "Voyage",
            "eob": "Soins complémentaires",
            "drug": "Médicaments",
            "dental": "Dentaire",
			"all": "Tout"
        },
		"modal": {
			"title": "Aucun résultat trouvé.",
			"message": "Nous n’avons rien trouvé qui corresponde à votre demande. Veuillez effectuer une nouvelle recherche."
		}
    }
}
</i18n>
