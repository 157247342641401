<!--
  Page template to be use for all Claim History pages.
-->
<template>
	<div class="margin-top">
		<BRow v-if="pageTitle">
			<BCol>
				<h1 class="mb-3">{{ pageTitle }}</h1>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" :lg="fullWidth ? '12' : '11'">
				<slot></slot>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';

export default {
	name: 'ClaimHistoryTemplate',
	components: { BRow, BCol },
	mixins: [IdMixin, BreadcrumbsManager],

	props: {
		pageTitle: {
			type: String,
			description: 'Top of page title',
			default: null
		},
		fullWidth: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.claimsHistory', 'en'),
					to: { name: Routes.CLAIM_HISTORY }
				}
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.claimsHistory', 'fr'),
					to: { name: Routes.CLAIM_HISTORY }
				}
			]
		);
	}
};
</script>

<style lang="scss" scoped>
.margin-top {
	margin-top: 15px !important;
	@media (min-width: 768px) {
		margin-top: 30px !important;
	}
}
</style>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "claimsHistory": "Claims History"
    }
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "claimsHistory": "Historique des demandes de règlement"
    }
  }
}
</i18n>
