<template>
	<div>
		<template v-if="showTotal">
			<BTable
				thead-class="d-lg-none head"
				:items="getFooterData"
				:fields="[
					{ key: 'total', label: $t('footer.total') },
					{ key: 'submittedAmount', label: $t('column.submittedAmount') },
					{ key: 'paidAmount', label: $t('column.paidAmount') },
					{ key: 'deductibleAmount', label: $t('column.deductibleAmount') },
					{ key: 'excludedAmount', label: $t('column.excludedAmount') }
				]"
				stacked="md"
				:borderless="true"
				class="claim-result-table-stacked"
			>
				<template #cell(submittedAmount)>
					<span>{{ submittedAmountTotal }}</span>
				</template>
				<template #cell(paidAmount)>
					<span>{{ paidAmountTotal }}</span>
				</template>
				<template #cell(deductibleAmount)>
					<span>{{ deductibleAmountTotal }}</span>
				</template>
				<template #cell(excludedAmount)>
					<span>{{ excludedAmountTotal }}</span>
					<InfoModal
						class="d-inline"
						automation-id="excluded-amount-popup"
						:modal-title="$t('modal.title')"
					>
						{{ $t('modal.text') }}
					</InfoModal>
				</template>
			</BTable>
		</template>
		<template v-if="showGrandTotal">
			<BTable
				thead-class="d-lg-none head"
				:automation-id="getAutomationId('claimSearchTotalAmounts')"
				:items="getSearchTotalAmounts"
				:fields="[
					{ key: 'grandTotal', label: $t('footer.grandTotal') },
					{ key: 'submittedAmountGrandTotal', label: $t('column.submittedAmount') },
					{ key: 'paidAmountGrandTotal', label: $t('column.paidAmount') },
					{ key: 'deductibleAmountGrandTotal', label: $t('column.deductibleAmount') },
					{ key: 'excludedAmountGrandTotal', label: $t('column.excludedAmount') }
				]"
				stacked="md"
				:borderless="true"
				class="claim-grand-total-table-stacked"
			>
				<template #cell(submittedAmountGrandTotal)>
					<span>{{ submittedAmountGrandTotal }}</span>
				</template>
				<template #cell(paidAmountGrandTotal)>
					<span>{{ paidAmountGrandTotal }}</span>
				</template>
				<template #cell(deductibleAmountGrandTotal)>
					<span>{{ deductibleAmountGrandTotal }}</span>
				</template>
				<template #cell(excludedAmountGrandTotal)>
					<span>{{ excludedAmountGrandTotal }}</span>
				</template>
			</BTable>
		</template>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BTable } from 'bootstrap-vue';
import InfoModal from '@/components/common/InfoModal';

@Component({
	name: 'ClaimSearchResultsTableFooterStacked',
	components: {
		BTable,
		InfoModal
	},
	props: {
		perPage: {
			type: Number,
			default: 20
		},
		submittedAmountTotal: {
			type: String,
			required: true
		},
		paidAmountTotal: {
			type: String,
			required: true
		},
		deductibleAmountTotal: {
			type: String,
			required: true
		},
		excludedAmountTotal: {
			type: String,
			required: true
		},
		submittedAmountGrandTotal: {
			type: String,
			required: true
		},
		paidAmountGrandTotal: {
			type: String,
			required: true
		},
		deductibleAmountGrandTotal: {
			type: String,
			required: true
		},
		excludedAmountGrandTotal: {
			type: String,
			required: true
		},
		showGrandTotal: {
			type: Boolean,
			default: false
		},
		showTotal: {
			type: Boolean,
			default: false
		}
	},
	mixins: [IdMixin]
})
export default class ClaimSearchResultsTableFooterStacked extends Vue {
	getFooterData() {
		let bottomContent = [
			{
				total: this.$t('footer.total'),
				submittedAmountTotal: this.submittedAmountTotal,
				paidAmountTotal: this.paidAmountTotal,
				deductibleAmountTotal: this.deductibleAmountTotal,
				excludedAmountTotal: this.excludedAmountTotal
			}
		];
		return bottomContent;
	}
	getSearchTotalAmounts() {
		let bottomContent = [
			{
				total: this.$t('footer.total'),
				submittedAmountGrandTotal: this.submittedAmountGrandTotalFormatted,
				paidAmountGrandTotal: this.paidAmountGrandTotalFormatted,
				deductibleAmountGrandTotal: this.deductibleAmountGrandTotalFormatted,
				excludedAmountGrandTotal: this.excludedAmountGrandTotalFormatted
			}
		];
		return bottomContent;
	}
}
</script>

<style lang="scss" scoped>
.claim-result-table-stacked {
	@media (min-width: 768px) {
		display: none;
	}
}
.table-footer-grand ::v-deep th:first-child {
	border-radius: 5px 0 0 0;
}
.table-footer-grand ::v-deep th:last-child {
	border-radius: 0 5px 0 0;
}
.claim-result-table-stacked.b-table-stacked-md {
	& ::v-deep tr:first-child {
		background-color: #e4e4e4;
		border-radius: 0px 0px 5px 5px;
		border: none;
	}
	&::v-deep tbody tr [data-label] div {
		font-weight: bold;
	}
	&::v-deep tbody tr [data-label='Total'] div {
		display: none;
	}
	&:last-child {
		border-radius: 0 5px 0 0;
	}
	&:not(:first-child) {
		border-left: none;
	}
	& ::v-deep td:first-child::before {
		font-size: 18px;
	}
}
.claim-grand-total-table-stacked {
	@media (min-width: 768px) {
		display: none;
	}
}
.claim-grand-total-table-stacked.b-table-stacked-md {
	& ::v-deep tr:first-child {
		background-color: $blue-dark;
		color: white;
		border-radius: 5px;
		border: none;
	}
	&::v-deep tbody tr [data-label] div {
		font-weight: bold;
	}
	&::v-deep tbody tr [data-label='Total'] div {
		display: none;
	}
	&:last-child {
		border-radius: 5px;
	}
	&:not(:first-child) {
		border-left: none;
	}
	& ::v-deep td:first-child::before {
		font-size: 18px;
	}
}
.excluded-amount-info-button-stacked {
	padding: 0px;
}
</style>
<i18n>
{
  "en": {
	"column": {
		"submittedAmount": "Submitted Amount",
		"paidAmount": "Blue Cross Paid",
        "deductibleAmount": "Deductible",
        "excludedAmount": "Excluded Amount"
	},
	"footer": {
		"total": "Total",
		"grandTotal": "Grand Total"
	},
	"modal": {
		"title": "Not sure why some of your payment was excluded?",
		"text": "In the Explanation of Benefits under each claim you can find clarification that outlines why some of your claim was not covered or eligible for payment."
	}
  },
  "fr": {
	"column": {
		"submittedAmount": "Montant soumis",
		"paidAmount": "Croix Bleue a payé",
        "deductibleAmount": "Franchise",
        "excludedAmount": "Montant exclu"
	},
	"footer": {
		"total": "Total",
		"grandTotal": "Grand Total"
	},
	"modal": {
		"title": "Pourquoi une partie de votre paiement est-elle exclue?",
		"text": "Dans le relevé des prestations de chaque demande de règlement, vous trouverez les raisons pour lesquelles une partie de votre demande de règlement n’était pas admissible au remboursement."
	}
  }
}
</i18n>
