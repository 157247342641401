var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showTotal)?[_c('BTable',{staticClass:"claim-result-table-stacked",attrs:{"thead-class":"d-lg-none head","items":_vm.getFooterData,"fields":[
				{ key: 'total', label: _vm.$t('footer.total') },
				{ key: 'submittedAmount', label: _vm.$t('column.submittedAmount') },
				{ key: 'paidAmount', label: _vm.$t('column.paidAmount') },
				{ key: 'deductibleAmount', label: _vm.$t('column.deductibleAmount') },
				{ key: 'excludedAmount', label: _vm.$t('column.excludedAmount') }
			],"stacked":"md","borderless":true},scopedSlots:_vm._u([{key:"cell(submittedAmount)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.submittedAmountTotal))])]},proxy:true},{key:"cell(paidAmount)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.paidAmountTotal))])]},proxy:true},{key:"cell(deductibleAmount)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.deductibleAmountTotal))])]},proxy:true},{key:"cell(excludedAmount)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.excludedAmountTotal))]),_c('InfoModal',{staticClass:"d-inline",attrs:{"automation-id":"excluded-amount-popup","modal-title":_vm.$t('modal.title')}},[_vm._v(" "+_vm._s(_vm.$t('modal.text'))+" ")])]},proxy:true}],null,false,370829448)})]:_vm._e(),(_vm.showGrandTotal)?[_c('BTable',{staticClass:"claim-grand-total-table-stacked",attrs:{"thead-class":"d-lg-none head","automation-id":_vm.getAutomationId('claimSearchTotalAmounts'),"items":_vm.getSearchTotalAmounts,"fields":[
				{ key: 'grandTotal', label: _vm.$t('footer.grandTotal') },
				{ key: 'submittedAmountGrandTotal', label: _vm.$t('column.submittedAmount') },
				{ key: 'paidAmountGrandTotal', label: _vm.$t('column.paidAmount') },
				{ key: 'deductibleAmountGrandTotal', label: _vm.$t('column.deductibleAmount') },
				{ key: 'excludedAmountGrandTotal', label: _vm.$t('column.excludedAmount') }
			],"stacked":"md","borderless":true},scopedSlots:_vm._u([{key:"cell(submittedAmountGrandTotal)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.submittedAmountGrandTotal))])]},proxy:true},{key:"cell(paidAmountGrandTotal)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.paidAmountGrandTotal))])]},proxy:true},{key:"cell(deductibleAmountGrandTotal)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.deductibleAmountGrandTotal))])]},proxy:true},{key:"cell(excludedAmountGrandTotal)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.excludedAmountGrandTotal))])]},proxy:true}],null,false,478508201)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }