<template>
	<BaseForm
		ref="premium-payments-search-date"
		v-slot="{ invalid }"
		:automation-id="getAutomationId('paymentDateSearchForm')"
		class="padding"
		@keydown.enter="onClick"
		@submit="onClick"
	>
		<BRow>
			<BCol cols="12" md="6" xl="5">
				<BaseDatePicker
					ref="fromDate"
					datepicker-id="claim-search-from-date-id"
					:value="searchCriteria.fromDate"
					:label="$t('label.serviceStartDate')"
					:initial-date="searchCriteria.fromDate"
					:max-selectable-date="getMaxDate()"
					:max-error-date="getMaxDate()"
					start-date
					:required="true"
					:required-error-msg="$t('error.requiredFromDate')"
					:before-today-and-after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
					:before-max-date-error-msg="$t('error.maxDate')"
					pill
					is-left
					:automation-id="getAutomationId('start')"
					data-tracking-id="premium-payment-statements-form-from-date-picker"
					@input="updateFromDate"
				>
					<template #suffix>
						<span class="suffix-text ml-2">{{ $t('label.dateSuffix') }}</span>
					</template>
				</BaseDatePicker>
			</BCol>
			<BCol cols="12" md="6" xl="5">
				<BaseDatePicker
					ref="toDate"
					datepicker-id="claim-search-to-date-id"
					:value="searchCriteria.toDate"
					:label="$t('label.serviceEndDate')"
					:max-selectable-date="getMaxDate()"
					:max-error-date="getMaxDate()"
					:start-date="false"
					:required="true"
					:from-date="searchCriteria.fromDate"
					:required-error-msg="$t('error.requiredToDate')"
					:before-today-and-after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
					:before-max-date-error-msg="$t('error.maxDate')"
					pill
					is-left
					:automation-id="getAutomationId('end')"
					@input="updateToDate"
				>
					<template #suffix>
						<span class="suffix-text ml-2">{{ $t('label.dateSuffix') }}</span>
					</template>
				</BaseDatePicker></BCol
			>
		</BRow>
		<BRow>
			<BCol cols="12" md="6" xl="5">
				<BaseSelect
					:value="searchCriteria.participant"
					class="dropdown"
					:label="$t('label.participant')"
					:validation-rules="{ required: true }"
					:options="participants"
					:automation-id="getAutomationId('province')"
					@change="onChangeParticipant"
				/>
			</BCol>
			<BCol cols="12" md="6" xl="5">
				<BaseSelect
					:value="searchCriteria.claimTypes"
					:is-plain="true"
					class="dropdown"
					:label="$t('label.benefit')"
					:validation-rules="{ required: true }"
					:options="benefits"
					:automation-id="getAutomationId('province')"
					@change="onChangeBenefit"
			/></BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseButton
					class="search-button m-0"
					variant="primary"
					:disabled="buttonDisabled || invalid"
					:label="$t('button.search')"
					is-right
					pill
					:automation-id="getAutomationId('searchByDate')"
					type="submit"
				></BaseButton>
			</BCol>
		</BRow>
	</BaseForm>
</template>

<script>
import Vue from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseForm from '@/components/common/base/BaseForm';
import BaseSelect from '@/components/common/base/BaseSelect';
import { format } from 'date-fns';
import { required } from 'vee-validate/dist/rules';
import ClaimHistory from '@/models/ClaimHistory.js';
import { extend } from 'vee-validate';
extend('required', required);

@Component({
	name: 'ClaimSearchFields',
	components: {
		BRow,
		BCol,
		BaseButton,
		BaseDatePicker,
		BaseForm,
		BaseSelect
	},
	mixins: [IdMixin],
	props: {
		participants: {
			type: Array,
			required: true
		}
	},
	watch: {
		locale() {
			this.benefits = [
				{ value: 'all', text: this.$t('benefit.all') },
				{ value: '05', text: this.$t('benefit.dental') },
				{ value: '04', text: this.$t('benefit.drug') },
				{ value: '03', text: this.$t('benefit.eob') },
				{ value: '02', text: this.$t('benefit.travel') },
				{ value: '01', text: this.$t('benefit.hospital') }
			];
		}
	}
})
export default class ClaimSearchFields extends Vue {
	buttonDisabled = false;
	benefits = [
		{ value: 'all', text: this.$t('benefit.all') },
		{ value: '05', text: this.$t('benefit.dental') },
		{ value: '04', text: this.$t('benefit.drug') },
		{ value: '03', text: this.$t('benefit.eob') },
		{ value: '02', text: this.$t('benefit.travel') },
		{ value: '01', text: this.$t('benefit.hospital') }
	];
	searchCriteria = {
		fromDate: format(
			new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
			'yyyy-MM-dd'
		),
		toDate: this.getMaxDate(),
		claimTypes: 'all',
		participant: 'all'
	};

	get locale() {
		return this.$root.$i18n.locale;
	}

	async mounted() {
		var tempSearchCriteria =
			(await ClaimHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
		if (tempSearchCriteria?.toDate) {
			this.searchCriteria = tempSearchCriteria;
			this.updateToDate(this.searchCriteria.toDate);
			this.updateFromDate(this.searchCriteria.fromDate);
		}
	}
	/**
	 * get the minimum date a member could choose. This is 3 years before todays date.
	 */
	getMinSelectableDate() {
		return format(new Date(new Date().getFullYear() - 3, 0, 1), 'yyyy-MM-dd');
	}

	/**
	 * get the minimum date a member could choose. This is 3 years before todays date.
	 */
	getMinErrorDate() {
		var minDate = new Date();
		minDate.setFullYear(minDate.getFullYear() - 2);
		return format(minDate, 'yyyy-MM-dd');
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	/**
	 * emit click event
	 */
	async onClick() {
		this.$emit('click', this.searchCriteria);
	}

	/**
	 * Update from date
	 * @param {string} fromDate
	 */
	updateFromDate(fromDate) {
		if (fromDate) {
			if (this.isValidDate(fromDate)) {
				this.searchCriteria.fromDate = format(
					new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]),
					'yyyy-MM-dd'
				);
			} else {
				if (fromDate.includes('-')) {
					var year = fromDate.split('-')[0];
					var month = fromDate.split('-')[1];
					var day = fromDate.split('-')[2];
					this.searchCriteria.fromDate = `${day}/${month}/${year}`;
				} else {
					this.searchCriteria.fromDate = fromDate;
				}
			}
		} else {
			this.searchCriteria.fromDate = null;
		}
		this.isButtonDisabled();
	}

	/**
	 * Update to date
	 * @param {string} to date
	 */
	updateToDate(toDate) {
		if (toDate) {
			if (this.isValidDate(toDate)) {
				this.searchCriteria.toDate = format(
					new Date(toDate.split('-')[0], toDate.split('-')[1] - 1, toDate.split('-')[2]),
					'yyyy-MM-dd'
				);
			} else {
				if (toDate.includes('-')) {
					var year = toDate.split('-')[0];
					var month = toDate.split('-')[1];
					var day = toDate.split('-')[2];
					this.searchCriteria.toDate = `${day}/${month}/${year}`;
				} else {
					this.searchCriteria.toDate = toDate;
				}
			}
		} else {
			this.searchCriteria.toDate = null;
		}
		this.isButtonDisabled();
	}

	onChangeParticipant(participant) {
		this.searchCriteria.participant = participant;
	}

	onChangeBenefit(benefit) {
		this.searchCriteria.claimTypes = benefit;
	}

	isButtonDisabled() {
		if (
			!this.searchCriteria ||
			!this.searchCriteria.fromDate ||
			!this.searchCriteria.toDate ||
			!this.isValidDate(this.searchCriteria.fromDate) ||
			!this.isValidDate(this.searchCriteria.toDate)
		) {
			this.buttonDisabled = true;
		} else {
			this.buttonDisabled = false;
		}
	}
	isValidDate(selectedDate) {
		return !isNaN(new Date(selectedDate)) && selectedDate.length === 10;
	}
}
</script>

<style lang="scss" scoped>
.padding {
	padding: 30px;
}
.search-by-date {
	border-radius: 10px;
	border: 1px solid $light-grey;
	margin-left: 0em;
}

h2 {
	font-size: $body-large-bold-font-size;
	font-weight: $font-regular;
	margin-bottom: 0;
}

.suffix-text {
	font-size: $body-small-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
}

.search-button {
	min-width: 100px;
	border: 1px solid #0079ad;
	border-radius: 30px;
	font-family: 'Josefin Sans', sans-serif;
	font-size: $body-regular-button-font-size;
	font-weight: normal;
	margin-left: 1em;
}
</style>

<i18n>
{
    "en": {
		"button": {
			"search": "Search"
		},
		"label": {
			"serviceStartDate": "Service start date",
            "serviceEndDate": "Service end date",
            "dateSuffix": "(DD/ MM / YYYY)",
            "participant": "Who is it for?",
			"benefit": "Benefit category"
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "This date is too far in the past. Our search results are limited to 24 months of history.",
			"maxDate": "The date you’ve entered is in the future. Please check the date and try again.",
			"requiredFromDate": "Enter a start date for your search.",
			"requiredToDate": "Enter an end date for your search."
		},
        "benefit": {
            "hospital": "Hospital",
            "travel": "Travel",
            "eob": "Extended Health Benefits",
            "drug": "Drug",
            "dental": "Dental",
            "all": "All"
        }
    },
    "fr": {
		"button": {
			"search": "Rechercher"
		},
		"label": {
			"serviceStartDate": "Début de la période de service",
            "serviceEndDate": "Fin de la période de service",
            "dateSuffix": "(JJ/MM/AAAA)",
            "participant": "Qui fait l’objet de la demande?",
            "benefit": "Catégorie de garantie"
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "La date est trop éloignée dans le passé. La période de recherche est limitée aux 24 derniers mois de l’historique.",
			"maxDate": "La date que vous avez entrée est ultérieure à la date d’aujourd’hui. Veuillez corriger la date et essayer à nouveau.",
			"requiredFromDate": "Entrez une date de début pour votre recherche.",
			"requiredToDate": "Entrez une date de fin pour votre recherche."
		},
        "benefit": {
            "hospital": "Hôpital",
            "travel": "Voyage",
            "eob": "Soins complémentaires",
            "drug": "Médicaments",
            "dental": "Dentaire",
			"all": "Tout"
        }
    }
}
</i18n>
